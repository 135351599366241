'use client'
import React  from 'react'

const ShowSalary = ({
  salary_info,
  size = 14,
  showTip = true,
}: any) => {
  const { text, type_text } = salary_info || {}

  return (
    <>
      {text}
      {
        showTip && type_text && (
          <span
            style={{
              color: '#2378e5',
              fontSize: size,
              letterSpacing: '0.12px',
              fontWeight: '400'
            }}
          >
            [{type_text}]
          </span>
        )}
    </>
  )
}

export default ShowSalary
