"use client"
import styled from '@emotion/styled';
import React from 'react'
import Image from 'next/image'
import Link from 'components/Link'


/* Images */
import appstore from '../../images/appstore.png'
import googleplay from '../../images/googleplay.png'
import { ReactComponent as QrcodeIcon } from '../../images/icons/qrcode.svg'
import adjustUrlList from 'helpers/adjustUrlList';
import QrCodeDraw from 'components/QrCodeDraw/QrCodeDraw';

const DownloadApp = (props: any) => {
  const { data } = props
  const { downloadApp, scanDownloadApp } = data?.foot || {}

  return (
    <DownloadAppStyle>
      <div className='download_btn_wrap'>
        <span className='download_btn'>
          {downloadApp}
          <div className='download_wrap'>
            <div className='download-app'>
              <Link to={adjustUrlList[4].link} external>
                <Image src={appstore} alt='scan qrcode' width={96} height={26} />
              </Link>
            </div>
            <div className='download-app download-app-google'>
              <Link to={adjustUrlList[4].link} external>
                <Image src={googleplay} alt='scan qrcode' width={96} height={26} />
              </Link>
            </div>
          </div>
        </span>
        <span className='download_btn_qrcode'>
          <QrcodeIcon />
          {/* <img src={QrcodeIcon}></img> */}
          <div className='qrcode_wrap'>
            <div className='qrcode_img_wrap'>
              {/* <Image src={download_app} alt='scan qrcode' width={108} height={108} /> */}
              <QrCodeDraw text={adjustUrlList[4].link} ecl='H' width={108} height={108} />
            </div>
            <p className='download_tip'>{scanDownloadApp}</p>
          </div>
        </span>
      </div>
    </DownloadAppStyle>
  )
}

export default DownloadApp

const DownloadAppStyle = styled.div`
  margin-left: 24px;
  .download_btn_wrap {
    display: flex;
    align-items: center;
  }
  .download_btn {
    position: relative;
    display: inline-block;
    padding: 8px 16px;
    border-radius: 30px;
    border: 1px solid #B8B8B8;
    font-size: 14px;
    color: #FCFCFC;
    cursor: pointer;
    &:hover {
      .download_wrap {
        visibility: visible;
      }
    }
  }
  .download_wrap {
    position: absolute;
    z-index: 2;
    top: -140px;
    left: -21px;
    display: flex;
    visibility: hidden;
    padding: 16px;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    background: #1854A0;
    box-shadow: 0px 2px 40px 0px rgba(0, 0, 0, 0.10);
    transition: visibility 0.2s ease-in-out; /* 添加过渡效果 */
    .download-app {
      display: flex;
      width: 134px;
      height: 40px;
      padding: 6px 10px;
      align-items: center;
      border-radius: 4px;
      background: #FFF;
    }
    .download-app-google {
      margin-top: 12px;
    }
  }
  .download_btn_qrcode {
    position: relative;
    display: inline-block;
    padding: 0 16px;
    border-radius: 30px;
    border: 1px solid #B8B8B8;
    margin-left: 12px;
    cursor: pointer;
    &:hover {
      .qrcode_wrap {
        visibility: visible;
      }
    }
  }
  .qrcode_wrap {
    position: absolute;
    z-index: 2;
    visibility: hidden;
    top: -180px;
    left: -40px;
    display: flex;
    padding: 16px 16px 10px 16px;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    background: #1854A0;
    box-shadow: 0px 2px 40px 0px rgba(0, 0, 0, 0.10);
    transition: visibility 0.2s ease-in-out; /* 添加过渡效果 */
    .qrcode_img_wrap {
      display: flex;
      width: 120px;
      height: 120px;
      padding: 6px;
      justify-content: center;
      align-items: center;
      background: #FFFFFF;
    }
    .download_tip {
      width: 120px;
      margin-top: 8px;
      color: #FFFFFF;
      text-align: center;
      font-size: 10px;
      font-weight: 600;
      line-height: normal;
    }
  }
`;
